import { LogosProps } from "../../models/Logos";

export const logosData: LogosProps[] = [
  {
    logo: "/assets/mastercard.svg",
  },
  {
    logo: "/assets/discover.svg",
  },
  {
    logo: "/assets/amex.svg",
  },
  {
    logo: "/assets/visa.svg",
  },
  {
    logo: "/assets/verve.svg",
  },
  {
    logo: "/assets/afrigo.svg",
  },
  {
    logo: "/assets/upi.svg",
  },
  {
    logo: "/assets/mastercard.svg",
  },
];

import * as React from "react";
import "./Footer.scss";
import { FooterItem, FooterMenu } from "../../../models/Footer";
import { Link } from "react-router-dom";

export const Footer: React.FC<{ footerMenu: FooterMenu[] }> = ({
  footerMenu,
}) => {
  return (
    <footer className="footer">
      <div className="footer-menu">
        {footerMenu.map((menu: FooterMenu, idx: number) => (
          <div key={idx} className="footer-menu-item">
            {menu.isCompanyTitle ? (
              <Link className="title company-title" to="/">
                <img src="assets/logo-name.svg" alt="logo name" />
                <img src="assets/logo-icon.svg" alt="logo icon" />
              </Link>
            ) : (
              <h4 className="title">{menu.title}</h4>
            )}
            {menu.items.map((subtitle: FooterItem, idxx: number) => (
              <Link key={idxx} className="sub-title" to={subtitle.url}>
                {subtitle.label}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </footer>
  );
};

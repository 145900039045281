import React, { useRef, useState } from "react";
import "./Cookie-policy.scss";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

type CookiePolicyProps = {
  title: string;
  paragraph?: string;
  list?: ListItem[];
};

type ListItem = {
  title: string;
  paragraph: string;
};

const CookiePolicy: React.FC<any> = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);
  const cookiePolicyContent: CookiePolicyProps[] = [
    {
      title: "What are cookies?",
      paragraph:
        "Cookies are small text files stored on your device by your web browser when you use our Services. They contain information about your interactions with our website, such as your preferences, browsing history, and session data.",
    },
    {
      title: "Types of cookies we use",
      list: [
        {
          title: "Essential cookies:",
          paragraph:
            "These cookies are necessary for the proper functioning of our website and services. They enable basic functions like page navigation, access to secure areas, and account authentication.",
        },
        {
          title: "Analytical/performance cookies:",
          paragraph:
            "These cookies allow us to analyze how visitors use our website and services, track user interactions, and improve our website's performance and user experience.",
        },
        {
          title: "Functionality cookies:",
          paragraph:
            "These cookies enable us to provide enhanced functionality and personalization, such as remembering your preferences and settings.",
        },
        {
          title: "Targeting/advertising cookies:",
          paragraph:
            "These cookies are used to deliver targeted advertisements and content based on your interests and browsing habits.",
        },
      ],
    },
    {
      title: "Managing cookies",
      paragraph:
        "You can control and manage cookies through your browser settings. Most web browsers allow you to block or delete cookies, or set preferences for certain types of cookies. However, please note that blocking or disabling certain cookies may affect the functionality of our website and services.",
    },
    {
      title: "Changes to this policy",
      paragraph:
        "We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any updates to this policy will be posted on our website with the effective date.",
    },
  ];
  const [cookiePolicy, setCookiePolicy] = useState(cookiePolicyContent);

  return (
    <main ref={scopeRef} className="cookie-policy-home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">LEGAL</h4>

          <div className="content-text">
            <h1 className="intro-text">cookie policy</h1>
            <h4 className="subtitle"> Effective 3rd of June, 2022</h4>
          </div>
        </div>
      </section>

      <section className="page-content" id="pageSection">
        <div className="content-wrapper">
          <p className="introduction">
            This cookie policy explains how we use cookies and similar tracking
            technologies on our website and services (collectively, the
            "Services"). By using the Services, you consent to the use of
            cookies and similar technologies as described in this policy.
          </p>

          <div className="cookie-policy-wrapper">
            {cookiePolicy.map((content: CookiePolicyProps, idx: number) => (
              <div key={idx} className="policy">
                <h3 className="title">{content.title}</h3>

                <div className="paragraph-wrapper">
                  {content.paragraph && (
                    <p className="paragraph">{content.paragraph}</p>
                  )}

                  {content.list && (
                    <ul className="list">
                      {content.list?.map((item: ListItem, idx: number) => (
                        <li className="list-item" key={idx}>
                          <h6 className="title">{item.title}</h6>
                          <p className="paragraph">{item.paragraph}</p>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="contact-support">
            <h3 className="title">Contact us</h3>
            <p className="text">
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a className="contact-email" href="mailto:support@smartvill.com">
                support@smartvill.com.
              </a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CookiePolicy;

import React, { useRef, useState } from "react";
import "./Privacy-policy.scss";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

type Policy = {
  title: string;
  paragraph: string;
  list?: string[];
  secondParagraph?: string;
  secondList?: string[];
};

const PrivacyPolicy: React.FC<any> = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);
  const privacyPolicyContent: Policy[] = [
    {
      title: "Information we collect",
      paragraph: "We may collect personal information when you:",
      list: [
        "Register for an account",
        "Use our services",
        "Make inquiries or requests",
        "Interact with our website or mobile app",
      ],
      secondParagraph:
        "The types of personal information we may collect include:",
      secondList: [
        "Name",
        "Contact information (email address, phone number)",
        "Account credentials",
        "Payment information",
      ],
    },
    {
      title: "How we use your information",
      paragraph: "We may use your personal information to:",
      list: [
        "Provide and maintain our services",
        "Process transactions",
        "Respond to inquiries and support requests",
        "Communicate with you about our services, promotions, and updates",
        "Improve our services and user experience",
        "Comply with legal obligations",
      ],
    },
    {
      title: "Information sharing",
      paragraph:
        "We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted third parties for specific purposes, such as processing payments or providing support services. These third parties are contractually obligated to keep your information confidential and secure.",
    },
    {
      title: "Data security",
      paragraph:
        "We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
    },
    {
      title: "Your choices",
      paragraph:
        "You can access and update your personal information by logging into your account. You may also opt-out of receiving promotional communications from us by following the instructions provided in the communication.",
    },
    {
      title: "Children’s privacy",
      paragraph:
        "Our services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us immediately.",
    },
    {
      title: "Changes to this policy",
      paragraph:
        "We may update this privacy policy from time to time. The updated policy will be posted on our website with the effective date. Your continued use of our services after any changes to this policy will constitute your acceptance of those changes.",
    },
  ];
  const [privacyPolicy, setPrivacyPolicy] = useState(privacyPolicyContent);
  return (
    <main ref={scopeRef} className="privacy-policy-home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">LEGAL</h4>

          <div className="content-text">
            <h1 className="intro-text">privacy policy</h1>
            <h4 className="subtitle"> Effective 3rd of June, 2022</h4>
          </div>
        </div>
      </section>

      <section className="page-content" id="pageSection">
        <div className="content-wrapper">
          <p className="introduction">
            Smartvill is committed to protecting your privacy. This privacy
            policy outlines how we collect, use, disclose, and safeguard your
            personal information when you use our website and services.
          </p>

          <div className="privacy-policy-wrapper">
            {privacyPolicy.map((content: Policy, idx: number) => (
              <div key={idx} className="policy">
                <h3 className="title">{content.title}</h3>

                <div className="paragraph-wrapper">
                  <p className="paragraph">{content.paragraph}</p>

                  {content.list && (
                    <ul className="list">
                      {content.list?.map((item: string, idx: number) => (
                        <li className="list-item" key={idx}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}

                  {content.secondParagraph && (
                    <p className="paragraph second">
                      {content.secondParagraph}
                    </p>
                  )}

                  {content.secondList && (
                    <ul className="list">
                      {content.secondList?.map((item: string, idx: number) => (
                        <li className="list-item" key={idx}>
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="contact-support">
            <h3 className="title">Contact us</h3>
            <p className="text">
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a className="contact-email" href="mailto:support@smartvill.com">
                support@smartvill.com.
              </a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicy;

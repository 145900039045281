import React, { Ref, useState } from "react";
import "./Nav-menu.scss";
import { MenuDropdown, NavMenuProps } from "../../../models/Nav-menu";
import { CaretDown, Moon, Sun } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

export const NavMenu: React.FC<{
  navMenu: NavMenuProps[];
  navRef: Ref<any>;
}> = ({ navMenu, navRef }) => {
  const [iProductsOpen, setProductsOpen] = useState(false);

  const toggleProducts = () => {
    setProductsOpen((isOpen) => !isOpen);
  };

  return (
    <header ref={navRef} className="menu-wrapper">
      <div className="menu">
        <Link to="/" className="theme-switcher-wrapper logo">
          <img src="assets/logo-name.svg" alt="logo name" />
          <img src="assets/logo-icon.svg" alt="logo icon" />
        </Link>
        <nav className="nav-menu">
          <ul className="nav-menu-list">
            {navMenu.map((menu: NavMenuProps, idx: number) => (
              <li key={idx} className="list-item">
                {menu.isDropdown ? (
                  <div onClick={toggleProducts} className="list-item-dropdown">
                    <p className="list-item-link">{menu.legend} </p>

                    <CaretDown color="#FFFFFF" />

                    {iProductsOpen && (
                      <div className="dropdown-menu">
                        <ul>
                          {menu.dropdownContent?.map(
                            (dropdown: MenuDropdown, idx: number) => (
                              <li key={idx}>
                                <Link to={dropdown.url}>{dropdown.label}</Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to={menu.link} className="list-item-link">
                    {" "}
                    {menu.legend}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
        <div className="theme-switcher-wrapper">
          {/* <div className="theme-switcher-background"></div>
          <div className="toggledOn">
            <div className="toggle-background"></div>
            <Moon color="#FFFFFF" weight="fill" size={16} />
          </div>
          <div className="toggledOff">
            <Sun color="#FFFFFF29" weight="fill" size={16} />
          </div> */}
        </div>
      </div>
    </header>
  );
};

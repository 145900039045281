import * as React from "react";
import "./Logos.scss";
import { LogosProps } from "../../../models/Logos";

export const Logos: React.FC<{ logos: LogosProps[] }> = ({ logos }) => {
  return (
    <section className="logos-wrapper">
      <div className="logos">
        {logos.map((logo: LogosProps, idx: number) => (
          <img key={idx} src={logo.logo} alt="logo" className="logo" />
        ))}
      </div>
    </section>
  );
};

import * as React from "react";
import "./Error.scss";
import { CaretRight } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const Error: React.FC<any> = (props: any) => {
  return (
    <main className="error-page">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">404</h4>

          <div className="content-text">
            <div className="intro-text-wrapper">
              <h1 className="intro-text">uh-oh.</h1>
              <h1 className="intro-text">you must be lost.</h1>
            </div>
            <h4 className="subtitle"> Some weird 404 description.</h4>
          </div>

          <Link to="/" className="content-btn">
            back to homepage <CaretRight color="#FFFFFF" size={16} />{" "}
          </Link>
        </div>
      </section>
    </main>
  );
};

export default Error;

import React, { useRef } from "react";
import "./About.scss";
import { NoIconFeatures } from "../../shared/No-icon-features/No-icon-features";
import { aboutPageFeatures } from "../../../viewmodels/shared-viewmodels/features-viewmodels";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

const About: React.FC<any> = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);
  return (
    <main ref={scopeRef} className="about-home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">OVERVIEW</h4>

          <div className="content-text">
            <h1 className="intro-text">we’re a leading technology company</h1>
            <h4 className="subtitle">
              Smartvill Solutions is Africa’s undisputed leading provider of
              world class digital solutions, leveraging cutting-edge technology
              to meet the evolving needs of our clients.
            </h4>
          </div>
        </div>

        <div className="about-map-wrapper">
          <img
            className="africa-map"
            src="assets/about-africa-map.svg"
            alt="world map"
          />
          <img
            className="world-map"
            src="assets/about-world-map.svg"
            alt="world map"
          />
        </div>
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">OFFERINGS</h4>

          <div className="content-text">
            <h1 className="intro-text">
              with innovation at the core of all we do
            </h1>
            <h4 className="subtitle">
              Our offerings are designed to revolutionize the way you experience
              seamless transactions. Trust us to deliver solutions that redefine
              convenience and efficiency.
            </h4>
          </div>
        </div>

        <div className="offerings-wrapper">
          <div className="offering">
            <div className="offering-background"></div>
            <h3 className="title">Payment solutions</h3>
            <p className="paragraph">
              Payment processing platforms, mobile payment applications, online
              payment gateways, point-of-sale (POS) systems, contactless payment
              technologies.
            </p>
          </div>

          <div className="offering">
            <div className="offering-background"></div>
            <h3 className="title">Non-payment solutions</h3>
            <p className="paragraph">
              Public transport, digital banking solutions, identity management,
              data analytics, business intelligence, compliance and regulatory
              solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">VALUES</h4>

          <div className="content-text">
            <h1 className="intro-text">we lead the way for others to follow</h1>
            <h4 className="subtitle">
              Established with a vision to transform financial ecosystems and
              enhance operational efficiency, here are our values.
            </h4>
          </div>
        </div>

        <NoIconFeatures features={aboutPageFeatures} />

        <img className="cms-one" src="assets/cms.svg" alt="cms" />
        <img className="cms-two" src="assets/cms.svg" alt="cms" />
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">PEOPLE</h4>

          <div className="content-text">
            <h1 className="intro-text">meet the brains behind the platform</h1>
            <h4 className="subtitle">
              We care deeply about the quality of our work and are focused on
              serving our clients, transforming our business, generating
              long-term shareholder value and driving a culture of excellence
              and accountability.
            </h4>
          </div>
        </div>

        <div className="teams-grid">
          <div className="team-item">
            <div className="team-item-background"></div>
            <div className="team-member-profile">
              <h4 className="team-member-name">ernest okosobo</h4>
              <p className="team-member-role">chief executive officer</p>
            </div>

            <div className="team-member-number">001</div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
          <div className="team-item">
            <div className="team-item-background"></div>
            <div className="team-member-profile">
              {/* <h4 className="team-member-name">james chimdindu</h4>
              <p className="team-member-role">product designer</p> */}
            </div>

            <div className="team-member-number">002</div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
            <div className="team-member-profile">
              {/* <h4 className="team-member-name">kingsley okoro</h4>
              <p className="team-member-role">frontend developer</p> */}
            </div>
            <div className="team-member-number">003</div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
            <div className="team-member-profile">
              {/* <h4 className="team-member-name">abolarin bukola</h4>
              <p className="team-member-role">frontend developer</p> */}
            </div>
            <div className="team-member-number">004</div>x
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
            <div className="team-member-profile">
              {/* <h4 className="team-member-name">prince akpabio</h4>
              <p className="team-member-role">frontend engineer</p> */}
            </div>
            <div className="team-member-number">005</div>
          </div>
          <div className="team-item">
            {" "}
            <div className="team-item-background"></div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;

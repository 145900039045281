import { FooterMenu } from "../../models/Footer";

export const footerMenu: FooterMenu[] = [
  {
    title: "Smartvill",
    items: [],
    isCompanyTitle: true,
  },
  {
    title: "Products",
    items: [
      { label: "Card issuance", url: "/cardissuance" },
      { label: "Card management system", url: "#" },
      { label: "Transport", url: "#" },
    ],
    isCompanyTitle: false,
  },
  {
    title: "Company",
    items: [
      { label: "About us", url: "/about" },
      { label: "Blog", url: "#" },
      { label: "Careers", url: "#" },
    ],
    isCompanyTitle: false,
  },
  {
    title: "Legal",
    items: [
      { label: "Terms of service", url: "/terms" },
      { label: "Privacy policy", url: "/policy" },
      { label: "Cookie policy", url: "/cookie" },
    ],
    isCompanyTitle: false,
  },
  // {
  //   title: "Resources",
  //   items: [
  //     { label: "APIs", url: "#" },
  //     { label: "Docs", url: "#" },
  //     { label: "Demo", url: "#" },
  //   ],
  //   isCompanyTitle: false,
  // },
];

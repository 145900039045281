import React, { useLayoutEffect, useRef } from "react";
import "./App.scss";
import { ThemeProvider } from "./contexts/ThemeContext";
import { Routes, Route } from "react-router-dom";
import { Home } from "./components/views/Home";
import { Contact } from "./components/views/Contact";
import { CardIssuance } from "./components/views/Card-issuance";
import { About } from "./components/views/About";
import { TermsOfService } from "./components/views/Terms-of-service";
import { PrivacyPolicy } from "./components/views/Privacy-policy";
import { Error } from "./components/views/Error";
import "./fonts/DS-DIGI.TTF";
import { CookiePolicy } from "./components/views/Cookie-policy";
import { NavMenu } from "./components/shared/Nav-menu/Nav-menu";
import { NavMenuData } from "./viewmodels/shared-viewmodels/nav-menu-viewmodel";
import { Footer } from "./components/shared/Footer/Footer";
import { footerMenu } from "./viewmodels/shared-viewmodels/footer-viewmodel";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSnap } from "./components/views/ScrollSnap";

const App: React.FC = () => {
  const navbarRef = useRef(null);
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const showNav = gsap
      .fromTo(
        navbarRef.current,
        {
          opacity: 0,
        },
        { opacity: 1, duration: 0.4 }
      )
      .progress(1);

    ScrollTrigger.create({
      start: "top top",
      end: "max",
      onUpdate: (self) => {
        self.direction === -1 ? showNav.play() : showNav.reverse();
      },
    });
  }, []);
  return (
    <div className="app">
      <video autoPlay muted loop>
        <source src="assets/bg-video.mp4" type="video/mp4" />
      </video>
      <ThemeProvider>
        <NavMenu navMenu={NavMenuData} navRef={navbarRef} />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cardissuance" element={<CardIssuance />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/cookie" element={<CookiePolicy />} />
          <Route path="/scrollto" element={<ScrollSnap />} />

          <Route path="*" element={<Error />} />
        </Routes>

        <Footer footerMenu={footerMenu} />
      </ThemeProvider>
    </div>
  );
};

export default App;

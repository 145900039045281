import React, { useRef } from "react";
import "./Home.scss";
import { Logos } from "../../shared/Logos/Logos";
import { logosData } from "../../../viewmodels/shared-viewmodels/logos-viewmodel";
import { Features } from "../../shared/Features/Features";
import {
  homePageCardEssentialsFeatures,
  homePageFeatures,
} from "../../../viewmodels/shared-viewmodels/features-viewmodels";
import { CaretRight } from "@phosphor-icons/react";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

const Home: React.FC = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);

  return (
    <main ref={scopeRef} className="home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <div className="content-text">
            <h1 className="intro-text">
              delivering cutting-edge technology for seamless payments and
              beyond
            </h1>
            <h4 className="subtitle">
              {" "}
              We empower financial institutions and government agencies to
              securely process high-volume payments, make sense of big data, and
              set-up scalable identity systems
            </h4>
          </div>
          <button className="content-btn">
            Learn more <CaretRight color="#FFFFFF" size={16} />{" "}
          </button>
        </div>

        <div className="globe-logos-section">
          <div className="globe-wrapper">
            <img
              className="globe-illustration one"
              src="assets/globe-1.svg"
              alt="globe"
            />
            <img
              className="globe-illustration two"
              src="assets/globe-2.svg"
              alt="globe"
            />
            <img
              className="globe-illustration three"
              src="assets/globe-3.svg"
              alt="globe"
            />
            <div className="logos-section-wrapper-background"></div>
          </div>

          <div className="logos-section-wrapper">
            <Logos logos={logosData} />
          </div>
        </div>
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">CARD ESSENTIALS</h4>

          <div className="content-text">
            <h1 className="intro-text">
              instant card issuance and data generation
            </h1>
            <h4 className="subtitle">
              {" "}
              End-to-end payment card personalization solution for "Issuers" and
              "Personalization Bureaus” with unified architecture for both
              instant and central issuance systems
            </h4>
          </div>

          <div className="content-btn-group">
            <button className="content-btn">
              Learn more <CaretRight color="#FFFFFF" size={16} />{" "}
            </button>
            <button className="content-btn">
              Contact us <CaretRight color="#FFFFFF" size={16} />{" "}
            </button>
          </div>
        </div>

        <div className="card-issuance-illustration">
          <div className="left-line-illustration">
            <img
              className="vector-1"
              src="assets/card-issuance-vector-1.svg"
              alt=""
            />
          </div>

          <div className="debit-card-illustration">
            <div className="debit-card-illustration-background">
              <div className="card-shade-2"></div>
              <div className="card-shade-1"></div>
            </div>

            <div className="middle">
              <div className="chip"></div>
              <div className="text">
                debit card <img src="assets/debit-card-bars.svg" alt="bars" />
              </div>
            </div>

            <div className="middle">
              <div className="chip">
                <div className="chip-background"></div>
                {/* Left Pentagon */}
                <div className="left-pentagon-line-1"></div>
                <div className="left-pentagon-line-2"></div>
                <div className="left-pentagon-line-3"></div>
                <div className="left-pentagon-line-4"></div>
                <div className="left-pentagon-line-5"></div>
                {/* Top Y vector */}
                <div className="top-y-leg-1"></div>
                <div className="top-y-leg-2"></div>
                <div className="top-y-leg-3"></div>
                {/* Top half straight line */}
                <div className="top-half-straight-line"></div>
                {/* Bottom Y vector */}
                <div className="bottom-y-leg-1"></div>
                <div className="bottom-y-leg-2"></div>
                <div className="bottom-y-leg-3"></div>
                {/* Bottom full straight line */}
                <div className="bottom-straight-line"></div>
                {/* Right Pentagon */}
                <div className="right-pentagon-line-1"></div>
                <div className="right-pentagon-line-2"></div>
                <div className="right-pentagon-line-3"></div>
                <div className="right-pentagon-line-4"></div>
                <div className="right-pentagon-line-5"></div>
              </div>
            </div>

            <div className="top">
              <span>2234</span>
              <span>5678</span>
              <span>9101</span>
              <span>1213</span>
            </div>

            <div className="bottom">
              <div className="cardholder-name">james c.</div>
              <div className="card-expiry-date">12/24</div>
              <img
                className="card-type"
                src="assets/card-type.svg"
                alt="card type"
              />
            </div>
          </div>

          <div className="right-line-illustration">
            <img
              className="vector-2"
              src="assets/card-issuance-vector-2.svg"
              alt=""
            />
          </div>
        </div>

        <div className="content">
          <div className="content-text">
            <h4 className="subtitle">
              *The numbers, dates, and names used on this website are for
              illustrative purposes only and do not represent real data or
              individuals. Any resemblance to actual persons, living or dead, or
              actual events is purely coincidental.
            </h4>
          </div>
        </div>

        <Features features={homePageCardEssentialsFeatures} />
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">TRANSPORT</h4>

          <div className="content-text">
            <h1 className="intro-text">
              public transit that’s more accessible, convenient and secure
            </h1>
            <h4 className="subtitle">
              A ready-made, robust solution for contactless payments in public
              transport, reducing operating costs for transport providers and
              enhancing commuter satisfaction and comfort.
            </h4>
          </div>

          <div className="transport-vector-wrapper">
            <img
              className="transport-illustration"
              src="assets/transport-radar.png"
              alt="world map"
            />

            {/* <img
              className="transport-illustration"
              src="assets/transport.svg"
              alt="world map"
            />

            <img
              className="transport-illustration radar"
              src="assets/radar.svg"
              alt="radar"
            /> */}
          </div>
        </div>

        <Features features={homePageFeatures} />
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">CARD MANAGEMENT SYSTEM</h4>

          <div className="content-text">
            <h1 className="intro-text">
              card and transaction management, built on a robust infrastructure
            </h1>
            <h4 className="subtitle">
              For retail chains, merchants, and service providers, our
              end-to-end solution involves the issue of smart cards, or
              magnetic-stripe or multi-application cards, which are acquired in
              closed loop systems by a flexible range of accepted issuers and
              acquirers.
            </h4>
          </div>
        </div>

        <Features features={homePageCardEssentialsFeatures} />

        <img className="cms-one" src="assets/cms.svg" alt="cms" />
        <img className="cms-two" src="assets/cms.svg" alt="cms" />
      </section>
    </main>
  );
};

export default Home;

import { NavMenuProps } from "../../models/Nav-menu";

export const NavMenuData: NavMenuProps[] = [
  { link: "/about", legend: "about", isDropdown: false },
  {
    link: "#",
    legend: "products",
    isDropdown: true,
    dropdownContent: [
      { label: "Card issuance", url: "/cardissuance" },
      { label: "Card management", url: "#" },
      { label: "Transport", url: "#" },
    ],
  },
  { link: "/contact", legend: "contact", isDropdown: false },
];

import { FeaturesProps, NoIconFeaturesProps } from "../../models/Features";
import {
  TrendDown,
  ShieldCheck,
  GoogleChromeLogo,
  Lightning,
  SealCheck,
  Flag,
  ArrowsLeftRight,
  Files,
  CodeSimple,
  Buildings,
  Star,
  Rss,
  Password,
  RocketLaunch,
} from "@phosphor-icons/react";

export const homePageFeatures: FeaturesProps[] = [
  {
    title: "Lower operating cost",
    icon: TrendDown,
    content:
      "We lower operating costs by up to 85% through savings derived from paperless ticketing, fewer transactions, and the flexibility of using multiple acquirers.",
  },
  {
    title: "Uncompromised cybersecurity",
    icon: ShieldCheck,
    content:
      "Our PCI DSS-certified payment gateway delivers uncompromised security of payment processing, including tokenization of personal data.",
  },
  {
    title: "Transport web portal",
    icon: GoogleChromeLogo,
    content:
      "The transport portal provides transaction information and analytics in one secure location. User-friendly widgets and dashboards deliver valuable data and intuitive reports. ",
  },
  {
    title: "Fast implementation",
    icon: Lightning,
    content:
      "Thanks to its API and Software as a Service (SaaS) model, we can deliver a flexible EMV system with L3 certification within three months. ",
  },
  {
    title: "Freedom to choose acquirer",
    icon: SealCheck,
    content:
      "Our connections are fast and flexible and offer providers the freedom of choice. ",
  },
  {
    title: "White label solution",
    icon: Flag,
    content:
      "We offer fully customizable partnerships with advanced development services that meet the needs of public transport providers. ",
  },
];

export const homePageCardEssentialsFeatures: FeaturesProps[] = [
  {
    title: "Lower operating cost",
    icon: TrendDown,
    content:
      "We lower operating costs by up to 85% through savings derived from paperless ticketing, fewer transactions, and the flexibility of using multiple acquirers.",
  },
  {
    title: "Uncompromised cybersecurity",
    icon: ShieldCheck,
    content:
      "Our PCI DSS-certified payment gateway delivers uncompromised security of payment processing, including tokenization of personal data.",
  },
  {
    title: "Transport web portal",
    icon: GoogleChromeLogo,
    content:
      "The transport portal provides transaction information and analytics in one secure location. User-friendly widgets and dashboards deliver valuable data and intuitive reports. ",
  },
  {
    title: "Fast implementation",
    icon: Lightning,
    content:
      "Thanks to its API and Software as a Service (SaaS) model, we can deliver a flexible EMV system with L3 certification within three months. ",
  },
  {
    title: "Freedom to choose acquirer",
    icon: SealCheck,
    content:
      "Our connections are fast and flexible and offer providers the freedom of choice. ",
  },
  {
    title: "White label solution",
    icon: Flag,
    content:
      "We offer fully customizable partnerships with advanced development services that meet the needs of public transport providers. ",
  },
];

export const aboutPageFeatures: NoIconFeaturesProps[] = [
  {
    title: "Innovation",
    content:
      "Smartvill strives to be at the forefront of technological advancement in payment and transport solutions. We believe in pushing the boundaries of what's possible to create innovative, user-centric products and services.",
  },
  {
    title: "Reliability",
    content:
      "In an increasingly digital world, reliability is paramount. Smartvill is committed to providing a reliable platform that customers can trust for their payment and transport needs, ensuring seamless transactions at all times.",
  },
  {
    title: "Customer-centricity",
    content:
      "At Smartvill, we put our customers first. We are dedicated to understanding their needs and delivering solutions that exceed their expectations, providing unparalleled convenience and satisfaction. ",
  },
  {
    title: "Sustainability",
    content:
      "Smartvill is committed to building businesses that benefit not only our customers but also the environment and society as a whole. We recognize our responsibility to minimize our environmental impact and contribute positively to the communities we serve. ",
  },
  {
    title: "Collaboration",
    content:
      "We believe in the power of collaboration to drive innovation and achieve shared goals. Smartvill fosters partnerships with industry leaders, developers, and other stakeholders to create synergies and deliver comprehensive solutions. ",
  },
  {
    title: "Accessibility",
    content:
      "Smartvill is committed to ensuring that our payment and transport solutions are accessible to all individuals, regardless of their background or abilities. We strive to design inclusive products and services that accommodate diverse needs and preferences. ",
  },
];

export const contactPageFeatures: FeaturesProps[] = [
  {
    title: "General communication",
    icon: ArrowsLeftRight,
    content: "For other queries, please get in touch with us via email",
    link: {
      label: "hello@smartvill.com",
      url: "mailto:hello@smartvill.com",
    },
  },
  {
    title: "Documentation",
    icon: Files,
    content: "Get an overview of our products’ features and how to use them.",
    btn: {
      label: "view docs",
      url: "",
    },
  },
  {
    title: "Developers",
    icon: CodeSimple,
    content: "Learn how to use APIs and SDKs to integrate our products ",
    btn: {
      label: "our APIs",
      url: "",
    },
  },
];

export const cardIssuancePageFeatures: FeaturesProps[] = [
  {
    title: "Multi institution support",
    icon: Buildings,
    content: "Centralized architecture for more than one institution",
  },
  {
    title: "Advanced quality control",
    icon: Star,
    content:
      "Integrated quality control tools before and after personalization to reduce lost cards",
  },
  {
    title: "PA-DSS compliance",
    icon: ShieldCheck,
    content: "Card Essentials was coded as per PA-DSS requirements",
  },
  {
    title: "Web-based branch application",
    icon: Rss,
    content:
      "Branch Manager does not require any additional installation at branches ",
  },
  {
    title: "PIN deployment at branch",
    icon: Password,
    content:
      "Card Essentials supports PIN deployment method at branches with major PINPAD devices ",
  },
  {
    title: "Fast personalization period",
    icon: RocketLaunch,
    content:
      "Card Essentials can personalize an EMV card with loyalty application in 6 Seconds in central issuance, in 40 Seconds as thermal, in 47 Seconds as emboss ",
  },
];

import * as React from "react";
import "./Features.scss";
import { FeaturesProps } from "../../../models/Features";
import { ArrowUpRight } from "@phosphor-icons/react";

export const Features: React.FC<{ features: FeaturesProps[] }> = ({
  features,
}) => {
  return (
    <section className="features-wrapper">
      {features.map((feature: FeaturesProps, idx: number) => (
        <div key={idx} className="feature">
          <div className="title-icon-section">
            {<feature.icon size={24} color="#FFFFFF" />}{" "}
            <h4 className="title">{feature.title}</h4>
          </div>

          <p className="content-section">{feature.content}</p>

          {feature.link && (
            <a href={feature.link.url} className="content-section-link">
              {feature.link.label}{" "}
              <ArrowUpRight color="#FFFFFF" weight="fill" />
            </a>
          )}

          {feature.btn && (
            <button className="content-section-link">
              {feature.btn.label} <ArrowUpRight color="#FFFFFF" weight="fill" />
            </button>
          )}
        </div>
      ))}
    </section>
  );
};

import React, { useRef, useState } from "react";
import "./Contact.scss";
import { Features } from "../../shared/Features/Features";
import { contactPageFeatures } from "../../../viewmodels/shared-viewmodels/features-viewmodels";
import { CaretRight } from "@phosphor-icons/react";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

type Tab = {
  tabId: number;
  active: boolean;
  label: string;
};

const Contact: React.FC<any> = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);

  const [tabs, setTabs] = useState<Tab[]>([
    { tabId: 1, active: true, label: "instant card issuance" },
    { tabId: 2, active: false, label: "card management system" },
    { tabId: 3, active: false, label: "transport" },
  ]);
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);

  const selectTab = (selectedTab: Tab) => {
    const filteredTabList = tabs.map((tab: Tab) => {
      if (selectedTab.tabId === tab.tabId) {
        tab.active = true;
      } else {
        tab.active = false;
      }
      return tab;
    });
    setTabs(filteredTabList);
    selectedTab.active = true;
    setActiveTab(selectedTab);
  };
  return (
    <main ref={scopeRef} className="contact-home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">CONTACT US</h4>

          <div className="content-text">
            <h1 className="intro-text">How can we help?</h1>
            <h4 className="subtitle">
              Get in touch with our sales and support teams for demos,
              onboarding support, or product questions.
            </h4>
          </div>
        </div>
      </section>

      <section className="product-section" id="pageSection">
        <div className="tab-section">
          <div className="tab-wrapper">
            <div className="tab-wrapper-background"></div>
            {tabs.map((tab: Tab) => (
              <div
                key={tab.tabId}
                className={tab.active ? "tab active" : "tab"}
                onClick={() => selectTab(tab)}
              >
                <div className="tab-background"></div>
                {tab.label}
              </div>
            ))}
          </div>
        </div>

        {activeTab.tabId === 1 && activeTab.active && (
          <div className="offerings-wrapper">
            <div className="offering">
              <div className="offering-background"></div>
              <h3 className="title">Sales</h3>
              <p className="paragraph">
                Speak to our sales team about plans, pricing, enterprise
                contracts, or request a demo.
              </p>

              <button className="offering-btn">
                talk to sales <CaretRight color="#FFFFFF" />
              </button>
            </div>

            <div className="offering">
              <div className="offering-background"></div>
              <h3 className="title">Help and support</h3>
              <p className="paragraph">
                Ask product questions, report problems, or leave feedback.
              </p>

              <button className="offering-btn">
                contact support <CaretRight color="#FFFFFF" />
              </button>
            </div>
          </div>
        )}
        {activeTab.tabId === 2 && activeTab.active && (
          <div className="offerings-wrapper">
            <div className="offering">
              <div className="offering-background"></div>
              <h3 className="title">Cards Supprt</h3>
              <p className="paragraph">
                Speak to our sales team about plans, pricing, enterprise
                contracts, or request a demo.
              </p>

              <button className="offering-btn">
                talk to sales <CaretRight color="#FFFFFF" />
              </button>
            </div>

            <div className="offering">
              <div className="offering-background"></div>
              <h3 className="title">Issuance Support</h3>
              <p className="paragraph">
                Ask product questions, report problems, or leave feedback.
              </p>

              <button className="offering-btn">
                contact support <CaretRight color="#FFFFFF" />
              </button>
            </div>
          </div>
        )}
        {activeTab.tabId === 3 && activeTab.active && (
          <div className="offerings-wrapper">
            <div className="offering">
              <div className="offering-background"></div>
              <h3 className="title">Transport Support</h3>
              <p className="paragraph">
                Speak to our sales team about plans, pricing, enterprise
                contracts, or request a demo.
              </p>

              <button className="offering-btn">
                talk to sales <CaretRight color="#FFFFFF" />
              </button>
            </div>

            <div className="offering">
              <div className="offering-background"></div>
              <h3 className="title">Transport Offerings</h3>
              <p className="paragraph">
                Ask product questions, report problems, or leave feedback.
              </p>

              <button className="offering-btn">
                contact support <CaretRight color="#FFFFFF" />
              </button>
            </div>
          </div>
        )}

        <Features features={contactPageFeatures} />
      </section>
    </main>
  );
};

export default Contact;

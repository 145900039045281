import * as React from "react";
import "./No-icon-features.scss";
import { NoIconFeaturesProps } from "../../../models/Features";

export const NoIconFeatures: React.FC<{ features: NoIconFeaturesProps[] }> = ({
  features,
}) => {
  return (
    <section className="no-icon-features-wrapper">
      {features.map((feature: NoIconFeaturesProps, idx: number) => (
        <div key={idx} className="feature">
          <div className="title-icon-section">
            <h4 className="title">{feature.title}</h4>
          </div>
          <p className="content-section">{feature.content}</p>
        </div>
      ))}
    </section>
  );
};

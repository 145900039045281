import React, { useRef } from "react";
import "./ScrollSnap.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { useGSAP } from "@gsap/react";
import { CaretRight } from "@phosphor-icons/react";
import { Features } from "../../shared/Features/Features";
import { homePageFeatures } from "../../../viewmodels/shared-viewmodels/features-viewmodels";
import { Logos } from "../../shared/Logos/Logos";
import { logosData } from "../../../viewmodels/shared-viewmodels/logos-viewmodel";
type Props = {};
gsap.registerPlugin(ScrollTrigger, useGSAP, ScrollToPlugin);

const ScrollSnap: React.FC<Props> = (props: Props) => {
  useGSAP(
    () => {
      const sections = document.querySelectorAll("section");

      // this scrolling object just allows us to conveniently call scrolling.enable(), scrolling.disable(), and check if scrolling.enabled is true.
      // some browsers (like iOS Safari) handle scrolling on a separate thread and can cause things to get out of sync (jitter/jumpy), so when we're animating the scroll position, force an update of GSAP tweens when there's a scroll event in order to maintain synchronization)
      const scrolling = {
        enabled: true,
        events: "scroll,wheel,touchmove,pointermove".split(","),
        prevent: (e: any) => e.preventDefault(),
        disable() {
          if (scrolling.enabled) {
            scrolling.enabled = false;
            window.addEventListener("scroll", gsap.ticker.tick, {
              passive: true,
            });
            scrolling.events.forEach((e, i) =>
              (i ? document : window).addEventListener(e, scrolling.prevent, {
                passive: false,
              })
            );
          }
        },
        enable() {
          if (!scrolling.enabled) {
            scrolling.enabled = true;
            window.removeEventListener("scroll", gsap.ticker.tick);
            scrolling.events.forEach((e, i) =>
              (i ? document : window).removeEventListener(e, scrolling.prevent)
            );
          }
        },
      };

      function goToSection(section: Element, anim?: gsap.core.Tween) {
        if (scrolling.enabled) {
          // skip if a scroll tween is in progress
          scrolling.disable();
          gsap.to(window, {
            scrollTo: { y: section, autoKill: false },
            onComplete: scrolling.enable,
            duration: 1,
          });

          anim && anim.restart();
        }
      }

      sections.forEach((section, i) => {
        // const intoAnim = gsap.from(section.querySelector(".right-col"), {
        //   yPercent: 50,
        //   duration: 1,
        //   paused: true,
        // });

        ScrollTrigger.create({
          trigger: section,
          start: "top bottom-=1",
          end: "bottom top+=1",
          onEnter: () => goToSection(section),
          onEnterBack: () => goToSection(section),
          markers: true,
          scrub: 1,
        });
      });
    },
    { dependencies: [] }
  );

  return (
    <main className="home-wrapper">
      <section className="hero-section">
        <div className="content">
          <h4 className="content-title">SMARTVILL</h4>

          <div className="content-text">
            <h1 className="intro-text">
              delivering cutting-edge technology for seamless payments and
              beyond
            </h1>
            <h4 className="subtitle">
              {" "}
              Smartvill Solutions is dedicated to delivering innovative payment
              and non-payment solutions to financial institutions and the public
              sector.
            </h4>
          </div>
          <button className="content-btn">
            Learn more <CaretRight color="#FFFFFF" size={16} />{" "}
          </button>
        </div>

        <div className="globe-logos-section">
          <div className="globe-wrapper">
            <img
              className="globe-illustration one"
              src="assets/globe-1.svg"
              alt="globe"
            />
            <img
              className="globe-illustration two"
              src="assets/globe-2.svg"
              alt="globe"
            />
            <img
              className="globe-illustration three"
              src="assets/globe-3.svg"
              alt="globe"
            />
            <div className="logos-section-wrapper-background"></div>
          </div>

          <div className="logos-section-wrapper">
            <Logos logos={logosData} />
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="content">
          <h4 className="content-title">CARD ESSENTIALS</h4>

          <div className="content-text">
            <h1 className="intro-text">
              instant card issuance and data generation
            </h1>
            <h4 className="subtitle">
              {" "}
              End-to-end payment card personalization solution for "Issuers" and
              "Personalization Bureaus” with unified architecture for both
              instant and central issuance systems
            </h4>
          </div>

          <div className="content-btn-group">
            <button className="content-btn">
              Learn more <CaretRight color="#FFFFFF" size={16} />{" "}
            </button>
            <button className="content-btn">
              Contact us <CaretRight color="#FFFFFF" size={16} />{" "}
            </button>
          </div>
        </div>

        <div className="card-issuance-illustration">
          <div className="left-line-illustration">
            <img
              className="vector-1"
              src="assets/card-issuance-vector-1.svg"
              alt=""
            />
          </div>

          <div className="debit-card-illustration">
            <div className="debit-card-illustration-background">
              <div className="card-shade-2"></div>
              <div className="card-shade-1"></div>
            </div>

            <div className="middle">
              <div className="chip"></div>
              <div className="text">
                debit card <img src="assets/debit-card-bars.svg" alt="bars" />
              </div>
            </div>

            <div className="middle">
              <div className="chip">
                <div className="chip-background"></div>
                {/* Left Pentagon */}
                <div className="left-pentagon-line-1"></div>
                <div className="left-pentagon-line-2"></div>
                <div className="left-pentagon-line-3"></div>
                <div className="left-pentagon-line-4"></div>
                <div className="left-pentagon-line-5"></div>
                {/* Top Y vector */}
                <div className="top-y-leg-1"></div>
                <div className="top-y-leg-2"></div>
                <div className="top-y-leg-3"></div>
                {/* Top half straight line */}
                <div className="top-half-straight-line"></div>
                {/* Bottom Y vector */}
                <div className="bottom-y-leg-1"></div>
                <div className="bottom-y-leg-2"></div>
                <div className="bottom-y-leg-3"></div>
                {/* Bottom full straight line */}
                <div className="bottom-straight-line"></div>
                {/* Right Pentagon */}
                <div className="right-pentagon-line-1"></div>
                <div className="right-pentagon-line-2"></div>
                <div className="right-pentagon-line-3"></div>
                <div className="right-pentagon-line-4"></div>
                <div className="right-pentagon-line-5"></div>
              </div>
            </div>

            <div className="top">
              <span>2234</span>
              <span>5678</span>
              <span>9101</span>
              <span>1213</span>
            </div>

            <div className="bottom">
              <div className="cardholder-name">james c.</div>
              <div className="card-expiry-date">12/24</div>
              <img
                className="card-type"
                src="assets/card-type.svg"
                alt="card type"
              />
            </div>
          </div>

          <div className="right-line-illustration">
            <img
              className="vector-2"
              src="assets/card-issuance-vector-2.svg"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="product-section">
        <div className="content">
          <h4 className="content-title">TRANSPORT</h4>

          <div className="content-text">
            <h1 className="intro-text">
              public transit that’s more accessible, convenient and secure
            </h1>
            <h4 className="subtitle">
              A ready-made, robust solution for contactless payments in public
              transport, reducing operating costs for transport providers and
              enhancing commuter satisfaction and comfort.
            </h4>
          </div>

          <div className="transport-vector-wrapper">
            <img
              className="transport-illustration"
              src="assets/transport-radar.png"
              alt="world map"
            />

            {/* <img
              className="transport-illustration"
              src="assets/transport.svg"
              alt="world map"
            />

            <img
              className="transport-illustration radar"
              src="assets/radar.svg"
              alt="radar"
            /> */}
          </div>
        </div>

        <Features features={homePageFeatures} />
      </section>

      <section className="product-section">
        <div className="content">
          <h4 className="content-title">CARD MANAGEMENT SYSTEM</h4>

          <div className="content-text">
            <h1 className="intro-text">
              card and transaction management, built on a robust infrastructure
            </h1>
            <h4 className="subtitle">
              For retail chains, merchants, and service providers, our
              end-to-end solution involves the issue of smart cards, or
              magnetic-stripe or multi-application cards, which are acquired in
              closed loop systems by a flexible range of accepted issuers and
              acquirers.
            </h4>
          </div>
        </div>

        <img className="cms-one" src="assets/cms.svg" alt="cms" />
        <img className="cms-two" src="assets/cms.svg" alt="cms" />
      </section>
    </main>
  );
};

export default ScrollSnap;

import React, { useRef, useState } from "react";
import "./Terms-of-service.scss";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

type Terms = {
  title: string;
  paragraph: string;
};
const TermsOfService: React.FC<any> = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);
  const termsConditionsContent: Terms[] = [
    {
      title: "Acceptance of terms",
      paragraph:
        "By accessing or using the Services, you affirm that you are at least 18 years of age and have the legal capacity to enter into these Terms. If you are accessing the Services on behalf of an entity, you represent and warrant that you have the authority to bind that entity to these Terms.",
    },
    {
      title: "Use of services",
      paragraph:
        "You may use the Services only for lawful purposes and in accordance with these Terms and all applicable laws and regulations. You are prohibited from using the Services in any manner that could damage, disable, overburden, or impair the Services or interfere with any other party's use and enjoyment of the Services.",
    },
    {
      title: "User accounts",
      paragraph:
        "To access certain features of the Services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to provide accurate and complete information when creating an account and to promptly update your account information as necessary.",
    },
    {
      title: "Intellectual property",
      paragraph:
        "All content, trademarks, logos, and other intellectual property displayed on the Services are the property of Smartvill or its licensors. You may not use, reproduce, distribute, or modify any content from the Services without prior written consent from Smartvill.",
    },
    {
      title: "Privacy",
      paragraph:
        "Your privacy is important to us. Please review our privacy policy to understand how we collect, use, and safeguard your personal information when you use the Services.",
    },
    {
      title: "Limitation of liability",
      paragraph:
        "In no event shall Smartvill, its affiliates, or their respective officers, directors, employees, or agents be liable for any damages, losses, or liabilities arising out of your use of the Services, including but not limited to indirect, incidental, special, or consequential damages.",
    },
    {
      title: "Termination",
      paragraph:
        "We reserve the right to suspend or terminate your access to the Services at any time without prior notice for any reason, including if you violate these Terms or for any other conduct that we determine to be harmful to the Services or our users.",
    },
    {
      title: "Changes to terms",
      paragraph:
        "We may update these Terms from time to time. The updated Terms will be posted on our website with the effective date. Your continued use of the Services after any changes to these Terms will constitute your acceptance of those changes.",
    },
    {
      title: "Governing law and jurisdiction",
      paragraph:
        "These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in [Jurisdiction].",
    },
  ];
  const [termsConditions, setTermsConditions] = useState(
    termsConditionsContent
  );

  return (
    <main ref={scopeRef} className="terms-home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">LEGAL</h4>

          <div className="content-text">
            <h1 className="intro-text">terms of service</h1>
            <h4 className="subtitle"> Effective 3rd of June, 2022</h4>
          </div>
        </div>
      </section>

      <section className="page-content" id="pageSection">
        <div className="content-wrapper">
          <p className="introduction">
            These Terms of Service ("Terms") govern your access to and use of
            the Smartvill website and services (collectively, the "Services").
            By accessing or using the Services, you agree to be bound by these
            Terms. If you do not agree to these Terms, please do not use the
            Services.
          </p>

          <div className="terms-conditions-wrapper">
            {termsConditions.map((content: Terms, idx: number) => (
              <div key={idx} className="terms">
                <div className="title-wrapper">
                  <h3 className="title-number">{idx + 1}.</h3>
                  <h3 className="title">{content.title}</h3>
                </div>

                <p className="paragraph">{content.paragraph}</p>
              </div>
            ))}
          </div>

          <div className="contact-support">
            <h3 className="title">Contact us</h3>
            <p className="text">
              If you have any questions or concerns about these Terms, please
              contact us at{" "}
              <a className="contact-email" href="mailto:support@smartvill.com">
                support@smartvill.com.
              </a>
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TermsOfService;

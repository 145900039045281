import React, { useRef } from "react";
import "./Card-issuance.scss";
import { Features } from "../../shared/Features/Features";
import { cardIssuancePageFeatures } from "../../../viewmodels/shared-viewmodels/features-viewmodels";
import { Square, TrendUp } from "@phosphor-icons/react";
import useAnimatePageSection from "../../../hooks/useAnimatePageSection";

const CardIssuance: React.FC<any> = (props: any) => {
  const scopeRef = useRef(null);
  // const {} = useAnimatePageSection("#pageSection", scopeRef);
  return (
    <main ref={scopeRef} className="card-issuance-home-wrapper">
      <section className="hero-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">CARD ESSENTIALS</h4>

          <div className="content-text">
            <h1 className="intro-text">
              instant card issuance and data generation
            </h1>
            <h4 className="subtitle">
              End-to-end payment card personalization solution for "Issuers" and
              "Personalization Bureaus” with unified architecture for both
              instant and central issuance systems
            </h4>
          </div>
        </div>

        <div className="key-features">
          <div className="key-feature-wrapper">
            <div className="key-feature-wrapper-background"></div>
            <div className="feature-name">
              <h2 className="title">Multiple card vendors</h2>{" "}
              <p className="subtitle">
                Support for all major card vendors and certification authorities
              </p>
            </div>
            <div className="feature-diagram">
              <div className="vendor-wrapper">
                <img src="assets/vendor=mastercard.svg" alt="mastercard logo" />
              </div>
              <img
                className="start"
                src="assets/position=start.svg"
                alt="start"
              />
              <img
                className="center"
                src="assets/position=center.svg"
                alt="center"
              />
              <img className="end" src="assets/position=end.svg" alt="end" />
            </div>
          </div>
          <div className="key-feature-wrapper">
            <div className="key-feature-wrapper-background"></div>
            <div className="feature-name">
              <h2 className="title">Multiple printer vendors</h2>{" "}
              <p className="subtitle">
                Support for all major card printer vendors
              </p>
            </div>
            <div className="feature-diagram">
              <div className="team-item">
                <div className="team-item-background"></div>
                <div className="team-member-profile">
                  <img
                    src="assets/printer-vendor-logo-1.svg"
                    alt="printer vendor logo"
                  />
                </div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
              <div className="team-item">
                <div className="team-item-background"></div>
                <div className="team-member-profile">
                  <img
                    src="assets/printer-vendor-logo-2.svg"
                    alt="printer vendor logo"
                  />
                </div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
                <div className="team-member-profile">
                  <img
                    src="assets/printer-vendor-logo-3.svg"
                    alt="printer vendor logo"
                  />
                </div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
                <div className="team-member-profile">
                  <img
                    src="assets/printer-vendor-logo-4.svg"
                    alt="printer vendor logo"
                  />
                </div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
                <div className="team-member-profile">
                  <img
                    src="assets/printer-vendor-logo-5.svg"
                    alt="printer vendor logo"
                  />
                </div>
              </div>
              <div className="team-item">
                {" "}
                <div className="team-item-background"></div>
              </div>
            </div>
          </div>
          <div className="key-feature-wrapper">
            <div className="key-feature-wrapper-background"></div>
            <div className="feature-name">
              <h2 className="title">Kiosk issuance</h2>{" "}
              <p className="subtitle">
                No physical interaction between the customer and any bank
                employee
              </p>
            </div>
            <div className="feature-diagram">
              <img
                className="fingerprint"
                src="assets/fingerprint.svg"
                alt="fingerprint"
              />
              <img
                className="fingerprint-scanner"
                src="assets/fingerprint-scanner-1.svg"
                alt="fingerprint scanner"
              />
            </div>
          </div>
          <div className="key-feature-wrapper">
            <div className="key-feature-wrapper-background"></div>
            <div className="feature-name">
              <h2 className="title">High level security</h2>{" "}
              <p className="subtitle">
                In addition to PA-DSS requirements, every transaction is
                encrypted entirely
              </p>
            </div>
            <div className="feature-diagram">
              <img
                className="security-pin"
                src="assets/security-illustration.svg"
                alt="security pin"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">ADDITIONAL FEATURES</h4>

          <div className="content-text">
            <h1 className="intro-text">
              competitive features driven by innovation
            </h1>

            <img
              className="additional-features-icon"
              src="assets/fade.svg"
              alt="features"
            />

            <h4 className="subtitle large">
              In addition to independent infrastructure, Card Essential has
              competitive features for both instant and central issuance systems
            </h4>
          </div>
        </div>

        <Features features={cardIssuancePageFeatures} />

        <img className="cms-one" src="assets/cms.svg" alt="cms" />
        <img className="cms-two" src="assets/cms.svg" alt="cms" />
      </section>

      <section className="product-section" id="pageSection">
        <div className="content">
          <h4 className="content-title">OUR STATISTICS</h4>

          <div className="content-text">
            <h1 className="intro-text">what gets measured gets managed</h1>
            <h4 className="subtitle">
              Integrated quality control tools before and after personalization
              to reduce lost cards
            </h4>
          </div>
        </div>

        <div className="stats-grid-wrapper">
          <div className="stats-wrapper">
            <div className="stats-wrapper-background"></div>
            <h3 className="title">Processed cards</h3>
            <h2 className="stat">20M+</h2>
            <p className="paragraph">
              Card Essentials has issued more than 20 million payment cards over
              the past year
            </p>
          </div>
          <div className="stats-wrapper large">
            <div className="stats-wrapper-background"></div>

            <div className="title-legend-wrapper">
              <h3 className="title">Distribution by vendor</h3>
              <div className="legend-wrapper">
                <div className="legend">
                  <Square color="#FFFFFF" />
                  <div className="label">Visa</div>
                </div>
                <div className="legend">
                  <Square weight="fill" color="#FFFFFF" />
                  <div className="label">Mastercard</div>
                </div>
                <div className="legend">
                  <Square weight="fill" color="#000000d4" />
                  <div className="label">Verve</div>
                </div>
              </div>
            </div>

            <div className="chart-wrapper">
              <h3 className="title">Total distributed</h3>
              <h2 className="stat">200K</h2>
              <p className="paragraph">Visa</p>

              <img
                className="chart-circle"
                src="assets/chart-circle.svg"
                alt="chart circle"
              />
            </div>
          </div>
          <div className="stats-wrapper">
            <div className="stats-wrapper-background"></div>
            <h3 className="title">Processed card spending</h3>
            <h2 className="stat">$500K</h2>
            <img
              className="diagonal-chart hidden"
              src="assets/chart.svg"
              alt="chart"
            />
            <img
              className="diagonal-chart"
              src="assets/chart.svg"
              alt="chart"
            />
            <div className="pill">
              <div className="pill-background"></div>
              <TrendUp color="#4A9485" />{" "}
              <p className="pill-text">20% increase from the past year</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default CardIssuance;
